<template>
  <div class="">
    <div class="container footer_container">
      <student-header/>
      <div class="pj-loading" v-if="!signupData || !pageContent"><i class="fas fa-circle-notch fa-spin"></i></div>
      <div class="welcome-flow-container" v-else>
        <div class="row align-items-center mobile-align content-align">
          <div class="col-12 col-lg-6 order-lg-3">
            <div class="desktop-banner-spacing">
              <div class="widget form-card">
                <div class="widget-splash splash-line splash-position"></div>
                <div class="widget-body">
                  <h1 class="text-center">{{pageContent && pageContent.formHeader ? pageContent.formHeader: 'CREATE STUDENT PROFILE'}}</h1>
                  <h4 class="text-center font-weight-normal mt-4 mb-4">{{pageContent && pageContent.formSubHeader ? pageContent.formSubHeader: 'Excelerate – the go-to project portal for students and companies in Denmark.'}}</h4>
                  <form
                    @submit.prevent="registerUser"
                  >
                    <div class="form-group">
                      <label>{{pageContent && pageContent.formEmailLabel ? pageContent.formEmailLabel: 'Email'}}</label>
                      <input
                        type="email"
                        v-model="email"
                        class="form-control form-control-lg"
                        name="email"
                        autocomplete="email"
                        :placeholder="pageContent && pageContent.formEmailPlaceholder ? pageContent.formEmailPlaceholder : 'Your@email.dk'"
                        autofocus
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>{{pageContent && pageContent.formPasswordLabel ? pageContent.formPasswordLabel: 'Password'}}</label>
                      <input
                        type="password"
                        v-model="password"
                        class="form-control form-control-lg"
                        name="new-password"
                        autocomplete="new-password"
                        :placeholder="pageContent && pageContent.formPasswordPlaceholder ? pageContent.formPasswordPlaceholder : 'Minimum 6 characters'"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>{{pageContent && pageContent.formConfirmPasswordLabel ? pageContent.formConfirmPasswordLabel: 'Confirm password'}}</label>
                      <input
                        type="password"
                        v-model="passwordConfirm"
                        :placeholder=" pageContent && pageContent.formConfirmPasswordPlaceholder ? pageContent.formConfirmPasswordPlaceholder : 'Confirm your password'"
                        :class="
                          passwordConfirm.length > 0 &&
                          passwordConfirm !== password
                            ? 'form-control  form-control-lg is-invalid'
                            : 'form-control  form-control-lg'
                        "
                        required
                      />
                    </div>
                    <div
                      class="alert alert-info"
                      v-if="passwordMessage.length > 0"
                    >
                      {{ passwordMessage }}
                    </div>
                    <div class="alert alert-warning" v-if="errorMessage">
                      {{ errorMessage }}
                    </div>
                    <div class="form-group text-right next-btn">
                      <button class="btn btn-primary w-200 btn-lg Explorer-CreateStudentProfile-Next" :disabled="isUpdating">
                      <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <span v-else>
                      <!-- {{ pageContent && pageContent.formHeader ? pageContent.formHeader : 'Next' }}  -->
                      Next
                    </span>
                      </button>
                    </div>
                    <!-- ?? -->
                    <div class="alert alert-success" v-if="successMessage">
                      {{ successMessage }}
                    </div>
                  </form>
                </div>
              </div>

              <div class="widget-footer-note footer-notes">
                {{pageContent && pageContent.pageAlreadyHaveAnAccountText ? pageContent.pageAlreadyHaveAnAccountText : 'Already have a student profile?'}}
                <router-link to="/signin" class="color-first Explorer-CreateStudentProfile-ChangeToSignIn"
                  >{{pageContent && pageContent.pageAlreadyHaveAnAccountLink ? pageContent.pageAlreadyHaveAnAccountLink : 'Sign in here'}}</router-link
                >
                <br>
                {{pageContent &&pageContent.pageSignUpText ? pageContent.pageSignUpText : 'Not a student?'}} <router-link to="/professional-signup" class="color-first Explorer-CreateStudentProfile-ChangeToProfessional">{{pageContent && pageContent.pageSignupLink ? pageContent.pageSignupLink : 'Create professional profile'}}</router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 order-lg-1 welcome-message">
            <div class="wm-content">
              <div class="header-position">
                <h1 class="heading-font">{{ signupData.title }}</h1>
                <ul class="usps p-0">
                  <li
                    v-for="(item, index) in signupData.signup_attributes"
                    :key="index"
                    class="list-font"
                  >
                    <span class="list-item-counter">{{ index + 1 }}</span
                    >{{ item.title }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentHeader from '../../components/student/StudentHeader.vue'

export default {
  name: "SignUp",

  data: function () {
    return {
      email: "",
      password: "",
      passwordConfirm: "",
      passwordMessage: "",
      role: "Student",
      referralCode: '',
      failureMessage: "",
      successMessage: "",
      terms: false,
      loading: true,
    };
  },
  components: {
    StudentHeader,
  },
  created() {
    this.$store.dispatch('clearAuthState')
    if(!this.$store.getters.studentContent) {
        this.$store.dispatch('studentContent')
      }
      if(localStorage.getItem('auth-token')) this.$router.push('dashboard')
    let queryEmail = this.$route.query.email;
    if (typeof queryEmail != "undefined" && queryEmail != "") {
      this.email = queryEmail;
    }
  },
  metaInfo: {
    title: "Student profile - Sign up on Excelerate | Excelerate.dk",
    meta: [
      {
        name: "description",
        content:
          "Excelerate is a FREE digital platform that helps students find thesis inspiration & thesis collaborations with companies. Go to Excelerate student sign up page.",
      },
      { property: "og:title", content: "Student profile - Sign up on Excelerate | Excelerate.dk" },
      {
        property: "og:description",
        content:
          "Excelerate is a FREE digital platform that helps students find thesis inspiration & thesis collaborations with companies. Go to Excelerate student sign up page.",
      },
    ],
  },
  computed: {
    isLoading(){
      return this.$store.getters.dynamicIsLoading
    },
    isUpdating() {
      return this.$store.getters.authIsUpdating
    },
    pageContent(){
      return this.$store.getters.signupContent
    },
    // todo room for improvement?
    signupData: function () {
      var vm = this;
      return vm.$store.getters.signupData;
    },
    signupImage() {
      return {
        backgroundImage: `url(${process.env.VUE_APP_API_BASE_URL}${this.signupData.image_path})`,
      };
    },
    errorMessage(){
      return this.$store.getters.authErrorMessage;
    }
  },
  mounted: function () {
    var vm = this;
    if(this.$store.getters.studentSignUpData && this.$store.getters.signupData) {// todo will remove the second half after complete implementation
      vm.loading = false
      return
    }
    vm.$store
      .dispatch("setSignupData", 'Student')// getStudentSignupData
      .then(() => (vm.loading = false));
      var refCode = vm.$route?.query?.refcode;
      if(typeof refCode !== typeof undefined){
        vm.referralCode = refCode
      }
  },
  methods: {
    registerUser() {
      var vm = this;
      const user = {
        email: this.email,
        password: this.password,
        payload: this.role,
        referralCode: this.referralCode,
      };
      if (vm.password === vm.passwordConfirm) {
        vm.$store.dispatch("registerUser", user);
      } else {
        vm.passwordMessage = "Please check your password confirmation";
      }
    },
  },
};
</script>
<style scoped>
.m-200{
  margin-top: 100px;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 95, 138, 0.2);
  border-right: 1.1em solid rgba(219, 95, 138, 0.2);
  border-bottom: 1.1em solid rgba(219, 95, 138, 0.2);
  border-left: 1.1em solid #BC1E73;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.splash-position{
  margin-top: -1px;
  margin-left: -2px;
  margin-right: -1px;
}
.widget{
  box-shadow: none !important
}
.usps li{
  margin-bottom: 20px;
}
.heading-font {
  font-size: 60px !important;
  color: white !important;
}
.form-card{
  margin-bottom: 0!important;
}
.footer-notes{
  margin: 10px 0 0px 0!important;
}
.form-group label{
  margin-bottom: 4px;
}
.next-btn{
  margin: 50px 0px 0px!important;
}
@media (max-width: 767px){
  .heading-font{
    font-size: 36px !important;
    margin: 70px 0px 0px!important;
  }
  .m-200{
    margin: 0;
    padding: 0;
  }
  .footer-notes{
  margin: 50px 0 30px 0!important;
}
}
.list-font {
  font-size: 18px !important;
  color: white !important;
}
.list-item-counter {
  margin-right: 1rem;
  background-color: #BC1E73;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 32px;
}
.content-align{
  /* height: 75vh; */
}
#user-pages .welcome-flow-container .welcome-message{
  margin-top: 0px !important;
}
@media screen and (max-width: 767px) {
  .mobile-align {
    flex-direction: column-reverse;
  }
  .content-align{
  height: auto;
}
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media (min-width: 600px){
  .desktop-banner-spacing{
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .widget .widget-splash.splash-line{
    margin-top: -3px;
  }
}
.pj-loading {
  width: 100%;
  height: 60vh;
  top: 0;
  left: 0;
  position: relative;
  border-radius: 8px;
}

.pj-loading i {
  position: absolute;
  top: 46%;
  left: 46%;
  font-size: 36px !important;
  color: #d22d89;
  transform: translate(-50%, -50%);
}
</style>

<style>
/* New styles for flexbox layout */
.footer_container {
  display: flex;
  flex-direction: column;
  @media (min-width: 1600px){
    /* display: none */
    height: 80vh;
  }
}

.welcome-flow-container {
  flex: 1; /* Allow this section to grow and take remaining space */
}

.student-footer {
  flex-shrink: 0; /* Prevent footer from shrinking */
  margin-top: auto; /* Push the footer to the bottom */
  /* Add other styles for your footer */
}
</style>